import React from "react";
import home_about_us from "../../assets/images/home_about_us.png"
import { colors } from "../../constants/style";
import { useNavigate } from "react-router-dom";

export const AboutUs = () => {
  const navigate = useNavigate();

  // General function to navigate to any route
  const handleNavigation = (path) => {
    navigate(path); // Dynamically navigate to the passed path
  };
  return (
    <div className={`flex gap-24 max-[1600px]:gap-16 max-[1300px]:px-10  max-[620px]:px-8 max-[620px]:mt-32
    max-[1000px]:flex-col max-[1000px]:px-36 max-[1600px]:px-28 pt-56 p-48 pb-60 max-[1150px]:pb-24 justify-center`}>
      <img className={`max-[1600px]:w-[70%] max-[1600px]:h-[30vh] w-full h-[40vh] max-[1000px]:w-full`} src={home_about_us} alt="" srcSet="" />
      <div>
        <h1 className={`text-2xl font-bold text-[${colors.primary_text_color}]`}>D'TECH ENGINEERING AFRICA LIMITED</h1>
        <p className={`mt-4 text-[${colors.primary_text_color}]`}>
          D'Tech Engineering Africa Limited is a multi-disciplined engineering
          consultancy based in Victoria Island, Lagos. We offer various services
          encompassing feasibility and conceptual studies, engineering design
          FEED and DED, project management, and manpower deployment across
          multiple sectors including construction, power generation, and oil and
          gas.
          <br />
          <br />
          Our commitment is to ensure your vision is meticulously planned,
          expertly executed, and successfully realized.
        </p>
        <button onClick={() => handleNavigation('/about-us')} 
        className={`py-2 text-xs my-8 px-10 text-[${colors.secondary_text_color}] bg-[#ffdcdc50] 
        hover:bg-[#D60505] hover:text-[#fff] transition-all duration-500`}>
             More About Us
        </button>
      </div>
     
    </div>
  );
};
