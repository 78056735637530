import React from 'react'
import subscribe_image from '../../assets/images/Mask group (2).png'

export const Subscribe = () => {
  return (
    <div className={`flex px-40 my-32 max-[1200px]:px-16  max-[900px]:flex-col  max-[900px]:px-40  max-[620px]:px-8`}>
            <img src={subscribe_image} alt="" srcSet="" />
            <div className={`p-20 bg-white max-[1000px]:p-10`}>
                <p className={`text-[#032D4C] text-2xl font-semibold w-3/4`}>Ready to power up your future with the best engineering technology</p>
                <p className={`text-[#032D4C] text-sm my-4 w-3/4`}>Subscribe to our newsletter for more updates</p>
                <div className={`text-sm flex gap-14 my-14 max-[1200px]:flex-col  max-[1200px]:gap-4`}>
                    <input type="text" placeholder='Enter your email address...' 
                    className={`border-b-2 w-[50%]  max-[1200px]:w-full border-[#D60505] focus:outline-none focus:border-gray-400 transition-all duration-300`} />
                    <button className={`text-[#FFFFFF] bg-[#D60505] px-4 py-2 `}>subscribe</button>
                </div>
            </div>
    </div>
  )
}
