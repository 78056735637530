import LocationIcon from "../assets/images/location_icon.png";
import PhoneNumberIcon from "../assets/images/phone_number_icon.png";
import HomeExpertise1 from "../assets/images/Group 459.png";
import HomeExpertise2 from "../assets/images/Group 460.png";
import HomeExpertise3 from "../assets/images/Group 461.png";
import HomeExpertiseCarousel1 from "../assets/images/image 4.png";
import HomeExpertiseCarousel2 from "../assets/images/image 5.png";
import HomeExpertiseCarousel3 from "../assets/images/image 6.png";
import { GrTechnology } from "react-icons/gr";
import blueivyLogo from "../assets/images/Blue-Ivy-Logo.png";
import aspogLogo from "../assets/images/aspog.jpg";
import radialLogo from "../assets/images/Radial-Circle-Logo-transparent.gif";

export const colors = {
  bg_primary: "#032D4C",
  bg_secondary: "#fff",
  primary_text_color: "#032D4C",
  secondary_text_color: "#D60505",
  text_color_white_50: "#ffffffd9",
};

export const home_expertise = [
  {
    logo: HomeExpertise1,
    text: "Civil Engineering & Building Construction Project Planning and Control",
  },
  {
    logo: HomeExpertise2,
    text: "Power Generation Project Planning and Control",
  },

  {
    logo: HomeExpertise3,
    text: "Oil & Gas Project Planning and Control",
  },
];

export const links = [
  {
    url: "/",
    title: "Home",
  },
  {
    url: "/about-us",
    title: "About Us",
  },
  {
    url: "/our-expertise",
    title: "Our Expertise",
  },
  {
    url: "/services",
    title: "Services",
  },
];

export const addressBox = [
  {
    logo: LocationIcon,
    title: "Location",
    details:
      "National Engineering Centre, Lagos, 1 Engineering Close, Victoria Island, Lagos 101241, Lagos",
  },
  {
    logo: PhoneNumberIcon,
    title: "Phone Number",
    details: "+2348039565803",
  },
];

export const key_service_carousel = [
  {
    image: HomeExpertiseCarousel1,
    title: "Oil & Gas Project Planning and Design",
    details:
      "We guarantee that every oil and gas project is completed successfully and on schedule, from start to finish, including feasibility studies, conceptual design, FEED, and detailed engineering design. We excel in engineering design and are true masters of our craft.",
  },
  {
    image: HomeExpertiseCarousel2,
    title: "Power Generation Project Planning",
    details:
      "We ensure that every power generation project is meticulously planned and completed successfully and on time, from start to finish.",
  },
  {
    image: HomeExpertiseCarousel3,
    title: "Construction Project Planning",
    details:
      "We guarantee that every project is completed successfully and on time. Trust us to manage your construction project with unparalleled expertise.",
  },
  {
    image: HomeExpertiseCarousel1,
    title: "Oil & Gas Project Planning and Design",
    details:
      "We guarantee that every oil and gas project is completed successfully and on schedule, from start to finish, including feasibility studies, conceptual design, FEED, and detailed engineering design. We excel in engineering design and are true masters of our craft.",
  },
];

export const expertiseOneKeyAchievements = [
  {
    image: <GrTechnology />,
    title: "Technologies",
    details:
      "We guarantee that every oil and gas project is completed successfully and on schedule, from start to finish, including feasibility studies, conceptual design, FEED, and detailed engineering design. We excel in engineering design and are true masters of our craft.",
  },
  {
    image: HomeExpertiseCarousel2,
    title: "Achievements",
    details:
      "We ensure that every power generation project is meticulously planned and completed successfully and on time, from start to finish.",
  },
  {
    image: HomeExpertiseCarousel3,
    title: "Future",
    details:
      "We guarantee that every project is completed successfully and on time. Trust us to manage your construction project with unparalleled expertise.",
  },
];

export const serviceList = [
  {
    image: "MdCastForEducation",
    title: "PROFESSIONAL CORPORATE TRAINING AND CONSULTANCY",
    description:
      "Our training programs are meticulously crafted to enhance the capabilities of professionals and project teams.",
    overview: `D'TECH Engineering Africa Limited excels in delivering comprehensive professional 
        corporate training and consultancy services tailored to meet the needs of various industries, 
        including Oil & Gas, Power Generation, and Construction. Our services are designed to empower
         organizations with the skills, knowledge, and tools necessary 
         to achieve project success, optimize performance, and maintain competitive advantage.`,
    types: [
      {
        title: "Professional Corporate Training",
        info: `Our training programs are meticulously crafted to enhance the capabilities of professionals 
            and project teams. We offer a wide range of courses 
            that focus on both foundational and advanced aspects of project management and engineering.`,
        details: [
          {
            name: "Project Management Training",
            list: [
              `Primavera P6: Advanced scheduling and project control techniques.`,
              `Microsoft Project: Efficient project planning, tracking, and reporting.`,
              `Risk Management: Identifying, assessing, and mitigating project risks.`,
              `Cost Management: Budgeting, cost control, and financial analysis.`,
            ],
          },
          {
            name: "Engineering and Technical Training",
            list: [
              `Design Software: Training on the latest design tools and technologies.`,
              `Civil Engineering Principles: From basic concepts to advanced engineering practices.`,
              `Construction Management:
               Effective management of construction projects, including planning, execution, and quality control.`,
            ],
          },
          {
            name: "Data Analysis and Management",
            list: [
              `Python: Programming for data analysis and automation.`,
              `Power BI: Data visualization and business intelligence.`,
              `SQL: Database management and data querying.`,
              `Advanced Excel: Complex data manipulation and analysis techniques.`,
            ],
          },
          {
            name: "Customized Training Solutions",
            list: [
              `Tailored programs to meet the specific needs of your organization.`,
              `Hands-on workshops and practical sessions to ensure immediate applicability.`,
              `Continuous learning support and post-training resources.`,
            ],
          },
        ],
      },
    ],
    commitment: `At D'TECH Engineering Africa Limited, we are committed 
    to delivering exceptional training and consultancy services that drive success 
    and foster growth. Our team of highly skilled professionals brings extensive industry 
    experience and a deep understanding of the challenges faced by our clients. We strive 
    to build lasting partnerships and provide solutions that are tailored to meet the unique needs of each organization.
    By choosing D'TECH Engineering Africa Limited, you are investing in a partner 
    dedicated to helping you achieve excellence in project management and engineering.`,
  },
  {
    image: "GoProject",
    title: "PROJECT MANAGEMENT PLANNING, EXECUTION AND CONTROL",
    description:
      "leader in providing comprehensive project management services, specializing in planning, execution, and control.",
    overview:
      "D'TECH Engineering Africa Limited is a leader in providing comprehensive project management services, specializing in planning, execution, and control. We leverage our expertise to ensure that projects are delivered on time, within budget, and to the highest quality standards. Our approach is grounded in industry best practices and tailored to meet the unique needs of each project.",
    types: [
      {
        title: "Project Management Planning",
        info: "Effective project planning is the cornerstone of successful project management. Our planning services include:",
        details: [
          {
            name: "Scope Definition",
            list: [
              "Clear articulation of project objectives and deliverables.",
              "Detailed work breakdown structures (WBS) to define all tasks and subtasks.",
            ],
          },
          {
            name: "Scheduling",
            list: [
              "Development of realistic and detailed project schedules using advanced tools like Primavera P6 and Microsoft Project.",
              "Critical path analysis to identify key tasks and dependencies.",
            ],
          },
          {
            name: "Resource Planning",
            list: [
              "Allocation of resources based on project requirements and availability.",
              "Resource leveling to prevent overallocation and ensure optimal utilization.",
            ],
          },
          {
            name: "Budgeting",
            list: [
              "Comprehensive cost estimation and budgeting.",
              "Implementation of cost control measures to manage expenses throughout the project lifecycle.",
            ],
          },
          {
            name: "Risk Management",
            list: [
              "Identification and assessment of potential risks.",
              "Development of risk mitigation strategies to minimize impact on project outcomes.",
            ],
          },
        ],
      },
      {
        title: "Project Execution",
        info: "Our execution services focus on translating the project plan into action while maintaining alignment with project goals:",
        details: [
          {
            name: "Project Kickoff",
            list: [
              "Initiation of project activities with a clear communication plan.",
              "Alignment of all stakeholders on project objectives and expectations.",
            ],
          },
          {
            name: "Team Coordination",
            list: [
              "Effective communication and collaboration among project team members.",
              "Regular progress meetings to ensure everyone is aligned and informed.",
            ],
          },
          {
            name: "Quality Management",
            list: [
              "Implementation of quality assurance processes to meet project standards.",
              "Continuous monitoring and improvement to ensure deliverables meet specified criteria.",
            ],
          },
          {
            name: "Procurement Management",
            list: [
              "Management of procurement processes, including vendor selection and contract management.",
              "Ensuring timely delivery of materials and services required for the project.",
            ],
          },
          {
            name: "Stakeholder Engagement",
            list: [
              "Active engagement with stakeholders to manage expectations and gather feedback.",
              "Transparent reporting and communication throughout the project lifecycle.",
            ],
          },
        ],
      },
      {
        title: "Project Control",
        info: "Our control services ensure that projects stay on track and meet their objectives:",
        details: [
          {
            name: "Performance Monitoring",
            list: [
              "Regular tracking of project progress against the plan.",
              "Use of key performance indicators (KPIs) to measure success and identify areas for improvement.",
            ],
          },
          {
            name: "Change Management",
            list: [
              "Handling project changes effectively to minimize disruptions.",
              "Maintaining a change log and ensuring all changes are documented and approved.",
            ],
          },
          {
            name: "Issue Resolution",
            list: [
              "Proactive identification and resolution of project issues.",
              "Escalation processes to address critical problems promptly.",
            ],
          },
          {
            name: "Cost Control",
            list: [
              "Continuous monitoring of project expenses.",
              "Implementation of corrective actions to manage cost variances and stay within budget.",
            ],
          },
          {
            name: "Reporting",
            list: [
              "Generation of regular status reports to keep stakeholders informed.",
              "Use of project management software for real-time updates and transparency.",
            ],
          },
        ],
      },
    ],
    commitment: `D'TECH Engineering Africa Limited is committed to excellence in project management.
       We bring a wealth of experience, industry knowledge, and a proven track record
        of delivering successful projects. Our team of experts works closely with clients
         to understand their needs and provide customized solutions that drive project success.
          By partnering with D'TECH Engineering Africa Limited, you can be assured of a dedicated 
          team that will manage your projects with precision, efficiency, and a focus on achieving your business goals.`,
  },
  {
    image: "MdEngineering",
    title: "CIVIL ENGINEERING & BUILDING CONSTRUCTION",
    description:
      "Our expertise spans from project conception through to completion, ensuring every project is delivered with the highest standards of quality, safety, and efficiency.",
    overview:
      "D'TECH Engineering Africa Limited excels in providing top-notch civil engineering and building construction services. Our expertise spans from project conception through to completion, ensuring every project is delivered with the highest standards of quality, safety, and efficiency. We leverage advanced technologies and innovative approaches to meet the unique demands of each project.",
    types: [
      {
        title: "Civil Engineering Services",
        info: "Our civil engineering services encompass a broad range of activities designed to support the infrastructure needs of our clients:",
        details: [
          {
            name: "Feasibility Studies and Site Analysis",
            list: [
              "Conducting thorough feasibility studies to assess the viability of projects.",
              "Detailed site analysis to inform design and construction decisions.",
            ],
          },
          {
            name: "Design and Engineering",
            list: [
              "Comprehensive civil engineering design services, including structural, geotechnical, and environmental engineering.",
              "Utilizing state-of-the-art design software to create detailed and accurate plans.",
            ],
          },
          {
            name: "Project Planning and Management",
            list: [
              "Development of robust project plans that include timelines, resource allocation, and budget estimates.",
              "Effective project management to ensure adherence to the plan and timely completion.",
            ],
          },
          {
            name: "Infrastructure Development",
            list: [
              "Design and construction of essential infrastructure such as roads, bridges, water supply systems, and drainage systems.",
              "Emphasis on sustainability and durability in all infrastructure projects.",
            ],
          },
          {
            name: "Regulatory Compliance",
            list: [
              "Ensuring all projects comply with local, national, and international regulations and standards.",
              "Handling permit applications and liaising with regulatory bodies.",
            ],
          },
        ],
      },
      {
        title: "Building Construction Services",
        info: "Our building construction services are tailored to meet the needs of various sectors, including residential, commercial, and industrial projects:",
        details: [
          {
            name: "Pre-Construction Services",
            list: [
              "Detailed project planning, including cost estimation, scheduling, and risk management.",
              "Value engineering to optimize project costs without compromising quality.",
            ],
          },
          {
            name: "Construction Management",
            list: [
              "Overseeing all aspects of the construction process from start to finish.",
              "Coordination of subcontractors, suppliers, and construction crews to ensure smooth operations.",
            ],
          },
          {
            name: "Quality Assurance and Control",
            list: [
              "Implementation of stringent quality control measures to ensure all work meets the highest standards.",
              "Regular inspections and testing to verify compliance with project specifications.",
            ],
          },
          {
            name: "Health and Safety",
            list: [
              "Commitment to maintaining a safe working environment for all personnel.",
              "Adherence to health and safety regulations and implementation of best practices on-site.",
            ],
          },
          {
            name: "Sustainable Construction",
            list: [
              "Incorporating sustainable building practices to minimize environmental impact.",
              "Use of eco-friendly materials and techniques to enhance energy efficiency and sustainability.",
            ],
          },
          {
            name: "Post-Construction Services",
            list: [
              "Comprehensive handover process, including final inspections and documentation.",
              "Ongoing support and maintenance services to ensure the longevity of the constructed facilities.",
            ],
          },
        ],
      },
    ],
    commitment: `D'TECH Engineering Africa Limited is dedicated to delivering exceptional 
      civil engineering and building construction services. Our team of experienced professionals 
      is committed to excellence in every aspect of our work, from initial planning and design to
       execution and final delivery. We pride ourselves on our ability to transform visions into
        reality by handling complex projects with precision and efficiency. By choosing D'TECH Engineering Africa Limited,
         you are partnering with a company that is passionate about building the future and delivering projects that stand the test of time.`,
  },
  {
    image: "PiOfficeChair",
    title: "ENGINEERING DESIGN FOR EPC PROJECTS",
    description:
      "Our approach integrates innovative design solutions with a deep understanding of project requirements.",
    overview:
      "D'TECH Engineering Africa Limited provides comprehensive engineering design services for Engineering, Procurement, and Construction (EPC) projects. Our approach integrates innovative design solutions with a deep understanding of project requirements, ensuring that every aspect of the project is meticulously planned and executed. Our services are tailored to meet the specific needs of various sectors, including Oil & Gas, Power Generation, and Construction.",
    types: [
      {
        title: "Engineering Design Services",
        info: "We offer a wide range of design services to meet the specific needs of each project:",
        details: [
          {
            name: "Conceptual Design",
            list: [
              "Initial Feasibility: Conducting feasibility studies to define project scope and objectives.",
              "Preliminary Design: Developing initial design concepts and alternatives to meet project requirements.",
            ],
          },
          {
            name: "Detailed Engineering Design",
            list: [
              "Structural Engineering: Designing structural systems that ensure the integrity and stability of buildings and infrastructure.",
              "Mechanical Engineering: Designing mechanical systems, including HVAC, plumbing, and process systems.",
              "Electrical Engineering: Developing electrical designs for power distribution, lighting, and control systems.",
              "Civil Engineering: Designing civil works, including foundations, roads, and drainage systems.",
            ],
          },
          {
            name: "Design Integration",
            list: [
              "Interdisciplinary Coordination: Ensuring seamless integration of all engineering disciplines to avoid conflicts and optimize performance.",
              "Design Optimization: Applying advanced modeling and simulation tools to enhance design efficiency and effectiveness.",
            ],
          },
          {
            name: "Procurement Support",
            list: [
              "Specification Development: Creating detailed specifications for procurement of materials and equipment.",
              "Vendor Coordination: Assisting with the selection and management of vendors to ensure compliance with design requirements.",
            ],
          },
          {
            name: "Construction Documentation",
            list: [
              "Detailed Drawings: Producing comprehensive construction drawings that provide clear instructions for implementation.",
              "Technical Specifications: Developing detailed specifications to guide construction activities and ensure quality.",
            ],
          },
          {
            name: "Quality Assurance and Control",
            list: [
              "Design Reviews: Conducting thorough reviews of design documents to ensure accuracy and compliance with standards.",
              "Compliance Checks: Verifying that designs meet regulatory requirements and industry standards.",
            ],
          },
          {
            name: "Project Support and Coordination",
            list: [
              "Site Coordination: Providing support during construction to address design-related issues and ensure adherence to plans.",
              "Change Management: Managing design changes effectively to minimize impact on project schedule and budget.",
            ],
          },
        ],
      },
    ],
    commitment: `At D'TECH Engineering Africa Limited, we are committed to delivering exceptional
   engineering design services for EPC projects. Our team of experienced engineers and designers brings a 
   wealth of knowledge and expertise to every project, ensuring that our designs are innovative, practical, 
   and aligned with project goals. We provide solutions that not only meet but exceed client expectations. 
   By choosing D'TECH Engineering Africa Limited, you partner with a company that values precision, efficiency, and excellence in engineering design.`,
  },
];

export const expertiseList = [
  {
    image: blueivyLogo,
    title: "Blue Ivy Consulting Limited",
    description:
      "100,000 Barrel Crude Storage Tank,  9km Alternative Crude Evacuation Pipeline and Management and Planning Training for Blue Ivy Employees",
    overview: ``,
    types: [
      {
        title: "100,000 Barrel Crude Storage Tank",
        info: `D'Tech Engineering Africa Limited delivered comprehensive engineering solutions for the 100,000 barrel crude storage tank project, encompassing:`,
        details: [
          {
            name: "",
            list: [
              `Front-End Engineering Design (FEED)`,
              `Detailed Engineering Design (DED)`,
              `⁠Project Planning, Coordination, and Close-out`,
            ],
          },
          {
            name: "Scope:",
            list: [
              "Project planning and scheduling",
              "Multidisciplinary team coordination",
              "Risk management and mitigation",
              "Cost estimation and control",
              "Quality assurance and control",
              "Close-out and handover management",
              "Piping, mechanical, electrical, and instrumentation design",
              "3D modeling and layout",
            ],
          },
          {
            name: "Benefits:",
            list: [
              "Timely project delivery",
              "Cost savings through efficient planning",
              "Enhanced project quality and safety",
              "Seamless handover to execution phase",
              "Accurate and detailed design documentation",
            ],
          },
        ],
        collabo: `D'Tech Engineering Africa Limited worked closely with Blue Ivy Consulting 
        Limited, to ensure successful project delivery, demonstrating expertise in managing complex stakeholder relationships.`,
      },
      {
        title: "9km Alternative Crude Evacuation Pipeline",
        info: `D'Tech Engineering Africa Limited delivered comprehensive engineering solutions, including:`,
        details: [
          {
            name: "",
            list: [
              `Front-End Engineering Design (FEED)`,
              `Detailed Engineering Design (DED)`,
              `⁠Project Planning, Coordination, and Close-out`,
            ],
          },
          {
            name: "Scope:",
            list: [
              "Project planning and scheduling",
              "Multidisciplinary team coordination",
              "Risk management and mitigation",
              "Cost estimation and control",
              "Quality assurance and control",
              "Close-out and handover management",
              "Piping, mechanical, electrical, and instrumentation design",
              "3D modeling and layout",
            ],
          },
          {
            name: "Benefits:",
            list: [
              "Enhanced crude evacuation efficiency",
              "Reduced transportation costs",
              "Increased operational reliability",
              "Improved environmental safety",
            ],
          },
        ],
        collabo: `D'Tech Engineering Africa Limited worked closely with Blue Ivy Consulting Limited, leveraging expertise to deliver a successful project.`,
      },
      {
        title: "Project Management and Planning Training",
        info: `D'Tech Engineering Africa Limited designed and delivered a comprehensive project management and planning training program for Blue Ivy Consulting Limited employees.`,
        details: [
          {
            name: "",
            list: [
              "Enhance project management skills and knowledge",
              "Improve project planning and execution capabilities",
              "Boost team productivity and collaboration",
              "Align with industry best practices (PMBOK, Agile)",
            ],
          },
          {
            name: "Scope:",
            list: [
              "Project lifecycle and phases",
              "Project planning (scope, schedule, budget, resources)",
              "Risk management and mitigation",
              "Communication and stakeholder management",
              "Team leadership and collaboration",
              "Monitoring and control (tracking, reporting, quality)",
              "Close-out and handover best practices",
            ],
          },
          {
            name: "Training Methodology:",
            list: [
              "Interactive lectures",
              "Case studies and group discussions",
              "Real-world examples and applications",
              "Hands-on exercises and workshops",
              "Pre- and post-training assessments",
            ],
          },
          {
            name: "Duration:",
            list: ["Three (3) days"],
          },
          {
            name: "Outcome:",
            list: [
              "Improved project delivery timelines",
              "Enhanced team productivity",
              "Better risk management",
              "Increased stakeholder satisfaction",
              "Aligns with industry best practices",
            ],
          },
          {
            name: "Certification:",
            list: ["(Professional Certification of Program Completion)"],
          },
        ],
        collabo: ``,
      },
    ],
  },
  {
    image: aspogLogo,
    title: "Advanced School of Power, Oil, and Gas (ASPOG)",
    description:
      "comprehensive project management training program to enhance the skills and knowledge of faculty and students",
    overview: `D'Tech Engineering Africa Limited delivered a comprehensive project management training program to enhance the skills and knowledge of faculty and students at Advanced School of Power, Oil and Gas.`,
    types: [
      {
        title: "Advanced School of Power, Oil and Gas, Nigeria",
        info: ``,
        details: [
          {
            name: "Objectives:",
            list: [
              "Build project management capacity",
              "Align with industry best practices (PMBOK, Agile)",
              "Improve project delivery and execution",
              "Enhance employability of graduates",
            ],
          },
          {
            name: "Scope:",
            list: [
              "Project lifecycle and phases",
              "Project planning (scope, schedule, budget, resources)",
              "Risk management and mitigation",
              "Communication and stakeholder management",
              "Team leadership and collaboration",
              "Monitoring and control (tracking, reporting, quality)",
              "Close-out and handover best practices",
            ],
          },
          {
            name: "Training Methodology:",
            list: [
              "Interactive lectures",
              "Case studies and group discussions",
              "Real-world examples and applications",
              "Hands-on exercises and workshops",
              "Pre- and post-training assessments",
            ],
          },
          {
            name: "Duration:",
            list: ["Three (3) days"],
          },
          {
            name: "Outcome:",
            list: [
              "Improved project delivery timelines",
              "Enhanced team productivity",
              "Better risk management",
              "Increased stakeholder satisfaction",
              "Aligns with industry best practices",
            ],
          },
          {
            name: "Certification:",
            list: [" (Certificate of Completion)"],
          },
        ],
        collabo: ``,
      },
    ],
  },
  {
    image: radialLogo,
    title: "Radial Circle Telecommunications Limited",
    description:
      "Project Management using Primavera P6 Training: Pipeline Construction and Maintenance Projects",
    overview: `D'Tech Engineering Africa Limited delivered specialized training to Radial Circle
     Telecommunications Limited employees on project management using Primavera P6, focusing on pipeline construction and maintenance projects.`,
    types: [
      {
        title: "Training Overview:",
        info: ``,
        details: [
          {
            name: "Objectives:",
            list: [
              "Enhance Primavera P6 skills for pipeline project management",
              "Improve project planning, scheduling, and control",
              "Increase efficiency and productivity",
              "Align with industry best practices (AACE, PMI)",
            ],
          },
          {
            name: "Scope:",
            list: []
            },
          {
            name: "Primavera P6 Fundamentals:",
            list: [
              "Navigation and interface",
              "Project creation and setup",
              "Activity creation and sequencing",
              "Resource allocation and leveling",
              "Baseline and tracking",
            ],
          },
          {
            name: "Pipeline Construction and Maintenance Project Management:",
            list: [
              "Project planning and scheduling",
              "Risk management and mitigation",
              "Budgeting and cost control",
              "Quality assurance and control",
              "Team leadership and collaboration",
            ],
          },

          {
            name: " Primavera P6 Advanced Topics:",
            list: [
              "Resource optimization",
              "Earned Value Management (EVM)",
              "Critical Path Method (CPM)",
              "Schedule risk analysis",
              "Reporting and dashboards",
            ],
          },
          {
            name: "Training Methodology:",
            list: [
              "Interactive lectures",
              "Hands-on exercises and workshops",
              "Real-world pipeline project examples",
              "Case studies and group discussions",
              "Pre- and post-training assessments",
            ],
          },
          {
            name: "Duration:",
            list: ["Three (3) days"],
          },
          {
            name: "Outcome:",
            list: [
              "Improved project delivery timelines",
              "Enhanced productivity and efficiency",
              "Better risk management",
              "Increased compliance with industry standards",
              "Effective project tracking and control",
            ],
          },
          {
            name: "Certification:",
            list: [" (Certificate of Completion)"],
          },
        ],
        collabo: ``,
      },
    ],
  },
];
