import { useRef } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { AboutUs } from "../../components/hompage/AboutUs";
import { ContactUs } from "../../components/hompage/ContactUs";
import { Expertise } from "../../components/hompage/Expertise";
import HeroBanner from "../../components/hompage/HeroBanner";
import { KeyService } from "../../components/hompage/KeyService";
import { KeyServiceCarousel } from "../../components/hompage/KeyServiceCarousel";
import { LocationEmailPhone } from "../../components/hompage/LocationEmailPhone";
import { Subscribe } from "../../components/hompage/Subscribe";
const HomePage = () => {
    const contactUsRef = useRef(null);

    return (
        <>
            <Header contactUsRef={contactUsRef} />
            <HeroBanner/>
            <AboutUs/>
            <Expertise/>
            <KeyService/>
            <KeyServiceCarousel/>
            <Subscribe/>
            <ContactUs ref={contactUsRef}/>
            <LocationEmailPhone/>
            <Footer />
        </>
    );
};

export default HomePage;
