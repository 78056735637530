import React from "react";
import intro_image from "../../assets/images/ourexpertise_intro.png";

export default function HeroBanner() {
  return (
    <div
      style={{ backgroundImage: `url(${intro_image})` }}
      className={`relative flex items-center bg-cover bg-center max-h-[500px] h-[60vh] w-full 
        max-[620px]:px-8
         max-[900px]:px-24`}
    >
      <div class="absolute inset-0 bg-black opacity-80"></div>
      <div  className={`h-full w-full`}>
        <div className={`h-full w-full flex flex-col items-end relative`}>
          <div className={`bg-[#032D4C] h-full w-[50%]  max-[900px]:hidden`}></div>
          <div className={`absolute text-[#fff] top-[30%] right-[20%] mr-20 border-2 border-[#D60505] px-10 py-8 w-[30%] 
            max-[1200px]:w-[40%]
            max-[900px]:relative
            max-[900px]:left-0
            max-[900px]:mr-0
            max-[900px]:top-[20%]
            max-[900px]:w-[100%]
          

            `}>
            <p className={`text-5xl font-bold max-[620px]:text-3xl`}>Future</p>
            <p className={`text-5xl font-bold max-[620px]:text-3xl`}>Technologies</p>
            <p className={`my-5`}>
              The future is fast approaching and a new era of digital innovation
              and disruption is here{" "}
            </p>  
          </div>
        </div>
      </div>
    </div>
  );
}
