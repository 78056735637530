import React from 'react'
import { MdConstruction, MdSafetyDivider } from 'react-icons/md'
import construction from "../../assets/images/construction.svg"

export const Banner = () => {
  return (
    <div style={{ backgroundImage: `url(${construction})` }}
    className={`relative bg-cover bg-center max-h-[500px] max-[620px]:h-[50vh]
      flex items-center
    h-[50vh] max-[900px]:h-[40vh]  w-full px-24 max-[600px]:px-10 `}>
         <div className="absolute inset-0 bg-gradient-to-tr from-black  to-black opacity-90" />
        <MdConstruction className={`text-8xl text-[#D60505] absolute  top-0 max-[1000px]:text-6xl my-4 right-10 z-10`}/>
        <div>
        <p className={`relative z-10 text-[#D60505] text-5xl max-[600px]:text-3xl font-semibold`}>Our Services</p>
        <p className={`w-3/5 max-[1000px]:w-full my-4 text-[#fff] relative z-10 max-[600px]:text-sm`}>We provide end-to-end project management solutions for construction and oil & gas industries. 
          Our services include project planning, execution, and oversight, ensuring quality, safety, and timely completion. 
          As experienced contractors, we deliver engineering expertise for infrastructure development, equipment procurement, and site management. With a focus on efficiency and compliance, 
          we support clients from concept to delivery, driving successful project outcomes in complex environments.</p>
        </div>
        <MdSafetyDivider  className={`text-8xl max-[1000px]:text-6xl text-[#D60505] bottom-0 left-10 absolute z-10`}/>
    </div>
  )
}
