import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="">
            <div className="bg-[#032D4C] px-40 py-24 max-[1100px]:px-24 max-[620px]:px-8">
                <div className="flex flex-row gap-[200px] max-[1100px]:gap-14 max-[900px]:flex-col">
                    <div>
                        <h2 className="text-[#D60505] text-[28px] font-bold">D'TECH ENGINEERING</h2>
                        <h2 className="text-[#F9F9F9] text-[28px] font-bold">AFRICA LIMITED</h2>
                        <p className="text-[#A9A9A9] text-[13px] font-[400] lg:w-[298px]">Our commitment is to ensure your vision is meticulously planned, expertly executed, and successfully realized.</p>
                    </div>
                    <div className="flex flex-row gap-[50px] lg:gap-[150px]">
                        <div>
                            <h3 className="text-[#F9F9F9] text-[20px] mb-2">Company</h3>
                            <ul className="text-[#7495AD] flex-col gap-2 flex">
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/our-expertise">Our Expertise</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/">Projects</Link></li>
                            </ul>
                        </div>
                        {/* <div>
                            <h3 className="text-[#F9F9F9] text-[20px] mb-2">Contact</h3>
                            <ul className="text-[#7495AD] flex-col gap-2 flex">
                                <li><Link to="/about-us">Phone Number</Link></li>
                                <li><Link to="/our-expertise">Email Address</Link></li>
                                <li><Link to="/services">Social Media</Link></li>
                                <li><Link to="/">Projects</Link></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-[#092234] text-[#fff] text-sm flex flex-col lg:flex-row lg:justify-between">
                    <div className="px-6 py-6">
                        <p>Copyright @2026, All rights reserved.</p>
                    </div>
                    <div className="flex flex-row gap-10 max-[1100px]:w-full
                max-[1100px]:justify-between max-[1100px]:items-end">
                        <ul className="flex flex-row gap-10 px-6 py-6">
                            <li><Link to="/privacy">Privacy</Link></li>
                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                        </ul>
                    
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
