import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Intro from "./Intro";

const AboutPage = () => {
    return (
        <>
            <Header />
            <Intro />
            <Footer />
        </>
    )
};

export default AboutPage;
