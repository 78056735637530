import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdCastForEducation, MdEngineering } from "react-icons/md";
import { GoProject } from "react-icons/go";
import { PiOfficeChair } from "react-icons/pi";
import { expertiseList, serviceList } from "../../constants/style";

const getIcon = (name) => {
  switch (name) {
    case "MdCastForEducation":
      return <MdCastForEducation className={`text-5xl text-[#D60505]`} />;
    case "GoProject":
      return <GoProject className={`text-5xl text-[#D60505]`} />;
    case "MdEngineering":
      return <MdEngineering className={`text-5xl text-[#D60505]`} />;
    case "PiOfficeChair":
      return <PiOfficeChair className={`text-5xl text-[#D60505]`} />;
    default:
      return ""; // Return null or a default icon if none match
  }
};

export const ExpertiseList = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleServiceClick = (service) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedService(service);
      setIsTransitioning(false);
    }, 300); // Adjust the delay to match the transition duration
  };

  const handleBackClick = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedService(null);
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <div className="relative py-36" >
      {!selectedService ? (
        <div
          className={`px-40 max-[1200px]:px-8 max-[900px]:grid-cols-2 max-[660px]:grid-cols-1 py-10 grid grid-cols-3 gap-10 gap-y-24 transition-opacity duration-500 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          {expertiseList.map((x, index) => (
            <div
              key={index}
              className={`flex flex-col justify-center items-center 
                    relative rounded-lg bg-[#f0f0f060] p-10 shadow-lg 
                    transition-transform duration-500 ease-in-out transform hover:scale-105`}
            >
              <div
                className={`flex justify-center items-center  
                     shadow-lg p-8 bg-[#fff] rounded-lg`}
              >
                <img className="w-24 h-20" src={x.image} alt="" />
                
              </div>

              <p
                className={`text-xl font-semibold text-[#032D4C] mt-8 uppercase`}
              >
                {x.title}
              </p>
              <p className={`text-sm text-[#525252] text-center py-4`}>
                {x.description}
              </p>
              <div
                className={`flex justify-center items-center rounded-full shadow-lg p-4 bg-[#fff] cursor-pointer`}
                onClick={() => handleServiceClick(x)}
              >
                <FaArrowRightLong className={`text-[#032D4C]`} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`transition-opacity duration-500 px-40 my-10 max-[900px]:px-8 max-[600px]:px-2 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          <div className="p-16 bg-white shadow-lg rounded-lg max-[600px]:px-8">
            <h2 className="text-2xl font-bold text-[#032D4C]">
              {selectedService.title}
            </h2>
            <hr />
            <p className="text-lg max-[600px]:text-base py-6 text-[#032D4C] ">{selectedService.overview}</p>
            {selectedService.types.map((type, type_index) => (
              <div key={type_index}>
                <p className="text-xl max-[600px]:text-base text-[#D60505] font-semibold py-2">{type.title}</p>
                <p className="text-base max-[600px]:text-sm text-[#959595] my-2">{type.info}</p>
                {type.details.map((detail, details_index) => (
                  <div key={details_index}>
                    <p className="text-base max-[600px]:text-sm text-[#959595] mt-6 font-bold">{detail.name}</p>
                    <ul className="text-base max-[600px]:text-sm text-[#959595] my-2 list-disc">{
                        detail.list.map((list_item, list_item_index)=>(
                          <li key={list_item_index}>{list_item}</li>
                        ))
                      }</ul>
                  </div>
                  
                ))}
                 <p className="text-lg max-[600px]:text-base py-6 text-[#032D4C]">{type.collabo}</p>
              </div>
            ))}
            <p className="text-lg max-[600px]:text-base py-6 text-[#032D4C]">{selectedService.commitment}</p>
            <button
              className="mt-4 p-2 bg-red-500 text-white rounded"
              onClick={handleBackClick}
            >
             
              Go Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
