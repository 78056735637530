import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { ExpertiseList } from "../../components/expertisepage/ExpertiseList.jsx";

import ExpertiseOne from "../../components/expertisepage/ExpertiseOne.jsx";
import { ExpertiseTwo } from "../../components/expertisepage/ExpertiseTwo.jsx";
import HeroBanner from "../../components/expertisepage/HeroBanner";

const OurExpertise = () => {
  return (
    <div>
      <Header />
      <HeroBanner />
      {/* <ExpertiseOne/> */}
      {/* <ExpertiseTwo/> */}
      <ExpertiseList/>
      <Footer />
    </div>
  );
};

export default OurExpertise;
