import React from "react";

export const KeyService = () => {

  
  return (
    <>
      <div className={`w-2/6 pl-40 mt-28 max-[1363px]:w-3/6 max-[900px]:w-full max-[900px]:px-40 max-[620px]:px-8`}>
        <p className={`text-[#D60505] my-4`}>OUR KEY SERVICE</p>
        <p className={`text-[#032D4C] my-4 text-2xl font-bold`}>
          We offer a range of key professional services designed to exceed your
          expectations, including:
        </p>
      </div>
    </>
  );
};
