import React from 'react'
import { addressBox, colors } from '../../constants/style'

export const AddressBox = () => {
  return (
    <>
        <div className={`w-full max-[620px]:flex-col max-[620px]:h-auto  max-[620px]:max-h-fit  max-[620px]:px-8
            flex gap-10 flex-row py-8 px-20 h-[20vh] max-h-[160px] text-[${colors.bg_secondary}] bg-[${colors.secondary_text_color}]`}>
                {
                    addressBox.map((address,index)=>(
                        <div key={index} className={`flex gap-4 flex-row w-1/2  max-[620px]:w-full`}>
                            <img className={`w-12 h-12`} src={address.logo} alt="" srcSet="" />
                            <div>
                                <p className={`text-lg font-bold`}>{address.title}</p>
                                <p className={`text-xs`}>{address.details}</p>
                            </div>
                        </div>
                    ))
                }
        </div>
    </>
  )
}
