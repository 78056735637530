import React from 'react'
import mail_logo from '../../assets/images/mail.png'
import location_logo from '../../assets/images/map-pin.png'
import phone_logo from '../../assets/images/phone-call.png'

export const LocationEmailPhone = () => {
  return (
    <div className={`flex justify-between my-24 px-64 text-[#032D4C] max-[1100px]:my-10 max-[1100px]:gap-10
    max-[1300px]:px-40 max-[620px]:px-8 max-[1100px]:flex-col max-[1100px]:justify-center max-[1100px]:items-start
    `}>
            <div className={`flex gap-6 justify-center items-center max-[1300px]:gap-2`}>
                <img className='max-[1100px]:w-16 max-[1100px]:h-16' src={location_logo} alt="" srcSet="" />
                <div >
                    <p className={`font-semibold`}>Location</p>
                    <p className={`text-xs bg-[#D60505] px-6 py-1 text-[#fff] rounded-full`}>
                        <a href="https://maps.app.goo.gl/znQz4JTctw2mnHpA7?g_st=ic">
                        Locate Us
                        </a>
                        </p>
                </div>
            </div>

            <div className={`flex gap-6 justify-center items-center  max-[1300px]:gap-2`}>
                <img className='max-[1100px]:w-16 max-[1100px]:h-16' src={mail_logo} alt="" srcSet="" />
                <div>
                    <p className={`font-semibold`}>Email</p>
                    <p className={`text-xs`}>dtechengineering.africa@gmail.com</p>
                </div>
            </div>
            <div className={`flex gap-6 justify-center items-center  max-[1300px]:gap-2`}>
                <img  className='max-[1100px]:w-16 max-[1100px]:h-16' src={phone_logo} alt="" srcSet="" />
                <div>
                    <p className={`font-semibold`}>Phone</p>
                    <p className={`text-xs`}>+2348039565803</p>
                </div>
            </div>
    </div>
  )
}
