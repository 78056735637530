import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { colors, links } from "../constants/style";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../assets/images/logo.jpg";
import { MdOutlineClose } from "react-icons/md";

const StyledHeader = styled.div``;

const Header = ({ contactUsRef }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log("ketem");
        setIsOpen(!isOpen);
       
        
    };

    const handleScrollToContactUs = () => {
        if (contactUsRef.current) {
          contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <StyledHeader>
            <header className="bg-[#fff] max-[800px]:px-0 px-6 md:px-10">
                <nav className="flex justify-between items-center max-[800px]:items-start relative">
                   <div>
                    <img className={`w-20 h-16 rounded-full`} src={logo} alt="" />
                   </div>
                    {/* Navbar Links */}
                    <ul
                        className={`max-[800px]:hidden flex  flex-row justify-end w-full max-[800px]:mr-0 mr-10 p-4 md:p-0
                        max-[800px]:mt-20
                        gap-4 md:gap-8 text-center  text-[${colors.primary_text_color}]`}
                    >
                        {links.map((link, index) => ( 
                            <li key={index} className={`max-[800px]:border-t-2`}>
                                <Link
                                    className="block max-[800px]:text-xl max-[800px]:font-semibold max-[800px]:py-6 
                                    max-[800px]:hover:text-[#fff]
                                    max-[800px]:hover:bg-[#032D4C] transition-all duration-300 hover:font-bold"
                                    to={link.url}
                                >
                                    {link.title}
                                </Link>
                            </li>
                        ))}
                    </ul>

                        
                    {isOpen && <ul
                        className={`hidden max-[800px]:block flex-col justify-end w-full max-[800px]:mr-0 mr-10 p-4 md:p-0
                        max-[800px]:mt-20
                        gap-4 md:gap-8 text-center  text-[${colors.primary_text_color}]`}
                    >
                        {links.map((link, index) => ( 
                            <li key={index} className={`max-[800px]:border-t-2`}>
                                <Link
                                    className="block max-[800px]:text-xl max-[800px]:font-semibold max-[800px]:py-6 
                                    max-[800px]:hover:text-[#fff]
                                    max-[800px]:hover:bg-[#032D4C] transition-all duration-300 hover:font-bold"
                                    to={link.url}
                                >
                                    {link.title}
                                </Link>
                            </li>
                        ))}
                    </ul>}
                    

                     {/* Hamburger menu for mobile */}
                    <div className={`hidden max-[800px]:block p-4 ${isOpen && 'absolute top-0 right-0'}`}>
                        <button
                            onClick={toggleMenu}
                            className={`text-[${colors.primary_text_color}] w-full relative z-20`}
                        >
                          {!isOpen ? <GiHamburgerMenu className="text-3xl "/> :  <MdOutlineClose className="text-3xl "/>}
                        </button>
                    </div>


                    {/* Quote button */}
                    <div
                        className={`max-[800px]:hidden bg-[${colors.bg_primary}] text-[${colors.bg_secondary}]`}
                    >
                        <button onClick={handleScrollToContactUs} className="text-center w-[200px] md:w-[288px] h-[50px] md:h-[100px] text-[16px] md:text-[20px] p-[10px] font-[500]">
                            Request a Quote
                        </button>
                    </div>
                </nav>

                {/* Mobile button display */}
                {isOpen && (
                    <div
                        className={`bg-[${colors.bg_primary}] hidden max-[800px]:block text-[${colors.bg_secondary}] mt-4`}
                    >
                        <button onClick={handleScrollToContactUs} className="text-center w-full h-[50px] text-[16px] p-[10px] font-[500]">
                            Request a Quote
                        </button>
                    </div>
                )}
            </header>
        </StyledHeader>
    );
};

export default Header;
