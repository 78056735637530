import React from "react";
import hero_banner from "../../assets/images/hero_banner.png";
import { colors } from "../../constants/style";
import { AddressBox } from "./AddressBox";
import { useNavigate } from "react-router-dom";

export default function HeroBanner() {
  const navigate = useNavigate();

  // General function to navigate to any route
  const handleNavigation = (path) => {
    navigate(path); // Dynamically navigate to the passed path
  };
  return (
    <>
      <div
        style={{ backgroundImage: `url(${hero_banner})` }}
        className={`relative bg-cover bg-center max-h-[700px] max-[620px]:h-[50vh] h-[80vh] max-[900px]:h-[40vh]  w-full`}
      >
        <div className="absolute inset-0 bg-gradient-to-tr from-black via-transparent to-black opacity-70" />
        <div className="relative z-10 flex items-center h-full">
          <div className={`p-32 max-[900px]:p-16 max-[620px]:p-8 w-full`}>
            <h1
              className={`text-6xl max-[900px]:text-4xl max-[620px]:text-2xl py-2 uppercase font-bold text-[${colors.bg_secondary}]`}
            >
              A Global Leader in
            </h1>
            <h1
              className={`text-6xl max-[900px]:text-4xl max-[620px]:text-2xl py-2 uppercase font-bold text-[${colors.bg_secondary}]`}
            >
              Premier Services
            </h1>
            <p  className={`my-3 text-xl max-[900px]:text-base max-[620px]:text-sm text-[${colors.bg_secondary}]`}>
              Through our expertise in engineering, we aim to tackle complex <br />
              challenges, drive progress, and create a lasting legacy of <br />
              excellence in the field of engineering.
            </p>
           <button onClick={() => handleNavigation('/our-expertise')} className={`
              bg-[#D60505] text-[#fff] px-10 py-4 rounded-sm hover:cursor-pointer
              hover:bg-[#ff5050] transition-all ease-in duration-500
            `}>
              Explore More
              </button>
              
          </div>
        </div>
        <div className={`w-3/6 absolute right-0 bottom-[-10%] 
           max-[1168px]:relative max-[1168px]:left-0 max-[1168px]:top-0 
           max-[1168px]:w-full
           `}>
        <AddressBox/>
        </div>
       
      </div>
    </>
  );
}
