import intro_image from "../../assets/images/about_banner.png";
import image8 from "../../assets/images/image8.png";
import layer2 from "../../assets/images/layer2.png";
import layer1 from "../../assets/images/Layer_1.png";
const Intro = () => {
  return (
    <div>
      {/* banner layer */}
      <div
        style={{ backgroundImage: `url(${intro_image})` }}
        className={`relative flex items-center bg-cover bg-center max-h-[500px] h-[60vh] w-full
           max-[900px]:justify-center max-[900px]:items-center
          
          `}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <p
          className={`relative z-20 text-5xl font-bold text-[#fff] pl-40  max-[900px]:px-8`}
        >
          About D’TECH Engineering
        </p>
      </div>

      {/* mission - vision layer */}
      <div className="">
        <div className="w-full overflow-hidden">
          <p className="py-20 w-[50%] text-[#032D4C] pl-40  max-[1300px]:w-[80%]  
          max-[620px]:w-[100%]  max-[620px]:px-8">
            D'TECH Engineering Africa Limited was founded with a passion for
            introducing innovative ideas, advanced technologies, and
            groundbreaking solutions to EPC projects in the Oil & Gas, Power
            Generation, Civil Engineering, and Construction sectors. We are
            committed to delivering exceptional Project Management Consultancy
            and supporting companies that strive for excellence in project
            management and controls.
          </p>
          <div
            className="bg-[#032D4C] relative py-20 pl-40 w-[90%] 
          max-[1300px]:px-8 max-[1300px]:w-[100%]
          max-[1300px]:flex
          max-[1300px]:flex
          max-[1300px]:flex-col
          max-[1300px]:items-center
          
          "
          >
            <p className="w-[50%] text-[#fff]  max-[900px]:w-[100%]">
              We provide specialized training and development for project teams,
              including Project Managers, Planners, Cost Engineers, Schedulers,
              Design Engineers, Business Development Officers, Service Managers,
              and Fresh graduates. Our comprehensive programs are designed to
              help beginners and professionals master their craft, enabling them
              to complete projects efficiently, meet quality standards, and stay
              within budget.
            </p>
            <ul className="ml-10 w-[50%] max-[900px]:w-[100%] mt-10 text-[#fff] text-[16px]">
              <li>
                <p>
                  <span className="text-[#D60505] mr-2">✓</span> Supported by
                  Expertise
                </p>
              </li>
              <li>
                <p>
                  <span className="text-[#D60505] mr-2">✓ </span> Team of Highly
                  Skilled Professionals with Significant Industry Achievements
                </p>
              </li>
              <li>
                <p>
                  <span className="text-[#D60505] mr-2">✓</span> Dedication to
                  Excellence
                </p>
              </li>
              <li>
                <p>
                  <span className="text-[#D60505] mr-2">✓</span> Round-the-Clock
                  Assistance
                </p>
              </li>
            </ul>
            <img
              src={image8}
              alt=" "
              className="absolute  w-auto max-w-[36%] h-[100vh]  right-0 bottom-0 
               max-[1300px]:hidden 
              "
            />
          </div>
          <div
            className="pl-40 py-20 max-[1300px]:w-full relative
           max-[1300px]:px-8 max-[1300px]:flex-col max-[1300px]:flex max-[1300px]:items-center"
          >
            <div className="lg:w-[495px] p-10 lg:p-0">
              <div className="flex flex-row justify-end">
                <img src={layer2} alt="" />
              </div>
              <h2 className="font-bold mb-2 text-[32px] text-[#032D4C]">
                Mission
              </h2>
              <p className=" text-[18px] font-[400] lg:w-[489px] text-[#032D4C]">
                To pioneer innovative engineering solutions that advance
                infrastructure development, foster sustainable growth, and
                positively impact communities across Africa and beyond.
              </p>
            </div>
            <div
              className="bg-[#D60505] max-[1300px]:mt-16 max-[1300px]:relative max-[1300px]:top-0 max-[1300px]:left-0 
            absolute bottom-0 w-[50%] flex flex-col justify-end p-20 pr-40
            max-[1300px]:h-auto
            max-[1300px]:px-8
            max-[1300px]:w-auto
            h-[830px] -z-20  right-0"
            >
              <div className="flex flow-row justify-end">
                <img src={layer1} alt="" />
              </div>
              <h2 className="font-bold mb-2 text-[32px] text-[#fff]">Vision</h2>
              <p className=" text-[18px] font-[400] lg:w-[489px] text-[#fff]">
                To be a leading force in Engineering, shaping the future of
                infrastructure, and driving socio-economic development across
                Africa.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* innovation layer */}
      <div className="p-10 pl-40 my-14 max-[900px]:pl-8 max-[900px]:mt-4">
        <h2 className="text-[#032D4C] font-bold text-[32px]">Core Values:</h2>
        <p className="text-[#032D4C] text-[18px] my-2">
          At D’Tech Engineering Africa we embrace:
        </p>
        <ul className="text-[#032D4C] text-[18px] w-[150px]">
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">I</span>
            <span className="ml-4 mr-2">-</span>
            <span>Innovation</span>
          </li>
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">E</span>
            <span className="ml-2 mr-2">-</span>
            <span>Excellence</span>
          </li>
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">I</span>
            <span className="ml-4 mr-2">-</span>
            <span>Integrity</span>
          </li>
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">C</span>
            <span className="ml-2 mr-2">-</span>
            <span>Collaboration</span>
          </li>
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">S</span>
            <span className="ml-2 mr-2">-</span>
            <span>Sustainability</span>
          </li>
          <li className="flex flex-row justify-betwee">
            <span className="font-bold">R</span>
            <span className="ml-2 mr-2">-</span>
            <span>Respect</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Intro;
