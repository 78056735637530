import React from 'react'

export const FeaturesServices = () => {
  return (
    <div className={`flex flex-col items-center justify-center pt-28 pb-20`}>
        <p className={`text-sm text-[#cbcbcb] `}>Features Services</p>
        <p className={`text-4xl text-[#032D4C] mt-2 font-semibold max-[600px]:text-2xl`}>A wide range of Engineering</p>
        <p className={`text-4xl text-[#032D4C] font-semibold max-[600px]:text-2xl`}>services</p>
    </div>
  )
}
