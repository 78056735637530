import React, { forwardRef, useState } from "react";
import facebook_logo from "../../assets/images/foundation_social-facebook.png";
import twitter_logo from "../../assets/images/foundation_social-twitter.png";
import instagram_logo from "../../assets/images/typcn_social-instagram.png";
import map_image from "../../assets/images/Mask group (3).png";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import axios from "axios";


export const ContactUs = forwardRef((props, ref) => {
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // New state to track loading

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const serviceId = process.env.REACT_APP_SERVICEID;
    const templateId = process.env.REACT_APP_TEMPLATEID;
    const publicKey = process.env.REACT_APP_SERVICEKEY;

    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        message_type: "contact_us",
        message: message,
        sender_name: senderName,
        sender_email: senderEmail,
      },
    };

    // Send the email using EmailJS
    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        data
      );
      console.log(res.data);
      // Clear form fields
      setSenderName("");
      setSenderEmail("");
      setMessage("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div
      ref={ref}
      className={`px-40 flex flex-row items-center max-[1300px]:flex-col max-[620px]:px-8`}
    >
      <div
        className={`relative w-fit flex flex-col items-start max-[1300px]:hidden`}
      >
        <p
          className={`text-xs rotate-[-90deg] text-[#032d4c] origin-left w-fit`}
        >
          FOLLOW US
        </p>
        <div className={`w-[2px] h-40 bg-[#D60505]`}></div>
        <div className={`ml-[-16%]`}>
          <a
            href="https://web.facebook.com/DTechSkills"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-60"
          >
            <img src={facebook_logo} alt="Facebook" />
          </a>

          <a
            href="https://twitter.com/dtech_skills"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-60"
          >
            <img src={twitter_logo} alt="Twitter" />
          </a>

          <a
            href="https://www.instagram.com/dtech_engineering/?next=%2Fofficial_davidyte%2F"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-60"
          >
            <img src={instagram_logo} alt="Instagram" />
          </a>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`w-2/4 pr-16 max-[900px]:w-[100%] max-[1300px]:w-3/4 max-[620px]:pr-0`}
      >
        <p className={`text-2xl font-semibold text-[#032D4C]`}>Contact Us</p>
        <hr className="w-[12%] border-t-2 border-[#D60505] my-2" />
        <p className={`text-sm text-[#032D4C] my-4`}>
          Reach out to us for any enquiries
        </p>

        <input
          type="text"
          className={`p-4 my-2 w-full border-[1px] border-gray-200 bg-[#fff] text-sm text-[3032D4C]`}
          name="name"
          placeholder="Name"
          value={senderName}
          onChange={(e) => setSenderName(e.target.value)}
        />

        <input
          type="email"
          className={`p-4 my-2 w-full border-[1px] border-gray-200 bg-[#fff] text-sm text-[3032D4C]`}
          name="email"
          placeholder="Your Email"
          value={senderEmail}
          onChange={(e) => setSenderEmail(e.target.value)}
        />

        <textarea
          rows={10}
          className={`p-4 my-2 w-full border-[1px] border-gray-200 bg-[#fff] text-sm text-[3032D4C]`}
          name="message"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <button
          type="submit"
          disabled={loading} // Disable button during loading
          className={`text-[#FFFFFF] bg-[#D60505] w-full py-4 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <svg
                className="animate-spin h-5 w-5 text-white mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              Sending...
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>

      <div
        className={`relative w-fit flex-row items-center justify-center hidden max-[1300px]:flex my-16`}
      >
        <p
          className={`text-2xl max-[620px]:text-base font-semibold text-[#032d4c] w-fit`}
        >
          FOLLOW US :{" "}
        </p>
        <div
          className={`flex text-5xl max-[620px]:text-3xl gap-5 px-9 max-[620px]:px-4`}
        >
          <FaFacebookSquare />
          <FaInstagramSquare />
          <FaTwitterSquare />
        </div>
      </div>

      <div>
        <img className={``} src={map_image} alt="" />
      </div>
    </div>
  );
});
