import React from "react";
import { colors, home_expertise } from "../../constants/style";
import expertise_image from "../../assets/images/Mask group (1).png"


export const Expertise = () => {
  return (
    <>
      <div
        className={`w-[90%] max-[1363px]:w-full 
          max-[1363px]:px-10 
          max-[620px]:px-0
          max-[1363px]:flex
          max-[1363px]:gap-10
          max-[1363px]:justify-center
          max-[1363px]:items-center
          max-[900px]:flex-col
           relative bg-[${colors.bg_primary}] text-[#ffffffbc] pl-40 pt-10 pb-10`}
      >
        <div className={`w-[40%]  max-[900px]:w-full max-[620px]:px-8  max-[900px]:px-24`}>
          <p className={`py-2`}>OUR EXPERTISE</p>
          <p className={`text-[#fff] text-3xl py-2 font-bold`}>
            Comprehensive Project Solutions <br /> and
             Professional Development
          </p>
          <p className={`py-2`}>
            We deliver comprehensive project solutions and professional
            development to ensure your projects are planned, executed, and
            controlled to the highest standards.
          </p>
          {home_expertise.map((address, index) => (
            <div key={index} className={`flex items-center gap-4 my-10`}>
              <img src={address.logo} alt="" srcSet="" />
              <p>{address.text}</p>
            </div>
          ))}
        </div>
        <img className={`absolute max-[1363px]:relative
            max-[1000px]:w-[60%] max-[1000px]:h-[60%] max-[900px]:w-full max-[900px]:h-[50vh] 
           
            max-[620px]:h-[30vh] 
            max-[620px]:w-[60%]
           bottom-0 right-0`} src={expertise_image} alt="" srcSet="" />
      </div>
    </>
  );
};
