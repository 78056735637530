import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import OurExpertise from './pages/OurExpertise';
import Services from './pages/Services';

function App() {
  return (
    <div>
        <Routes>
         <Route path='/' element={<HomePage/>} />
         <Route path='/about-us' element={<AboutPage/>} />
         <Route path='/our-expertise' element={<OurExpertise/>} />
         <Route path='/services' element={<Services/>} />
       </Routes>
      
    </div>
  );
}

export default App;
