import React from "react";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import { key_service_carousel } from "../../constants/style";
import key_service_arrow_left from "../../assets/images/Group 366.png";
import key_service_arrow_right from "../../assets/images/Group 365.png";
import { useNavigate } from "react-router-dom";

export const KeyServiceCarousel = () => {
  const navigate = useNavigate();

  // General function to navigate to any route
  const handleNavigation = (path) => {
    navigate(path); // Dynamically navigate to the passed path
  };
  return (
    <div
      className={`mx-40 my-14  max-[900px]:mt-44  max-[620px]:mx-8`}
      style={{
        position: "relative",
      }}
    >
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        customButtonGroup={<ButtonGroup />}
        containerClass="container-padding-bottom"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1468,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 1100,
              min: 1,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1468,
              min: 1100,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {key_service_carousel.map((service, index) => (
          <div
          onClick={() => handleNavigation('/services')}
            className={`bg-[#F4F4F4] hover:bg-[#D60505] hover:cursor-pointer px-16 max-[620px]:px-8 py-20 h-[70vh] max-[620px]:h-auto max-[620px]:max-h-auto max-h-[500px]  max-[620px]:mr-0 mr-8 group duration-500 transition-all`}
            key={index}
          >
            <div
              className={`p-6 bg-[#FFFFFF] w-28 h-28 rounded-[50%] flex justify-center`}
            >
              <img
                className={`w-14 h-14`}
                src={service.image}
                alt=""
                srcSet=""
              />
            </div>
            <p
              className={`text-2xl font-semibold text-[#032d4c] my-4 group-hover:text-[#FFF]`}
            >
              {service.title}
            </p>
            <p
              className={`text-sm text-[#032d4c] my-4 group-hover:text-[#FFFFFF]`}
            >
              {service.details}
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group px-8 absolute top-[-90px] right-0">
      <button
        className={currentSlide === 0 ? "disable mx-10" : " mx-10"}
        onClick={() => previous()}
        value={`prev`}
      >
        <img src={key_service_arrow_left} alt="" srcSet="" />
      </button>
      <button onClick={() => next()} value={`next`}>
        <img src={key_service_arrow_right} alt="" srcSet="" />
      </button>
    </div>
  );
};
