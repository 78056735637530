import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { Banner } from "../../components/servicepage/Banner";
import { FeaturesServices } from "../../components/servicepage/FeaturesServices";
import { ServicesList } from "../../components/servicepage/ServicesList";

const Services = () => {
    return (
        <div>
            <Header />
              <Banner/>
              <FeaturesServices/>
              <ServicesList/>
            <Footer />
        </div>
    );
};

export default Services;
